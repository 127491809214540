
    'use strict';
    import { IPosition,ICampaign } from "./../../services/campaign.service";
    import {ILead,ILeadResult,ILeadsService,ILeadStats} from "./../../services/leads.service";
    import {IStatisticsService} from './../../services/stats.service';
    import $ = require("jquery");
    import angular = require("angular");
    import { Transition } from "@uirouter/core";
    import * as moment from 'moment';
    let now = moment().format('LLLL');
    

    export interface IPositionSelectable extends IPosition{
        selected:boolean;
        selectedTraffic:boolean;
    }
    export default class LeadSolutionController {
        static $inject: Array<string> = ["campaign","leadresult","LeadsService","$translate","StatisticsService","$q","DTOptionsBuilder","$transition$"];


        campaign:ICampaign;
        leads:ILead[] = [];
        leadsFiltered:ILead[] =[];
        headers:string[] = [];
        funnelData:any[];
        leadstats:ILeadStats;
        trafficstats:any;
        activeButton:number;
        currentPosition:IPosition=null;
        selectedPosition = null;
        dtOptions:any;
        loading:boolean=false;
        hideOverview:boolean=false;
        selectedPositions:any[] = [];
        selectedPositionsList:string="";
        selectedPositionsLeads:any[] = [];
        leadselect:string="new";

        total_pageviews = 0;
        total_adviews = 0;

        constructor(campaign:ICampaign,leadresult:ILeadResult,private LeadsService:ILeadsService,private $translate:angular.translate.ITranslateService,private StatisticsService:IStatisticsService,private $q:angular.IQService,private DTOptionsBuilder,$transition$:Transition) {
            this.campaign = campaign;
            this.leads = leadresult.data;
            this.leadstats = leadresult.metadata;
            this.headers = leadresult.headers;
            this.activeButton = 1;

            //Plugin "Utlimate Date / Time Sorting" konfigurieren
            //@ts-ignore
            $.fn.dataTable.moment("DD.MM.YYYY");

            angular.forEach(this.campaign.positions,(position:IPosition)=>
            {
                this.total_adviews = this.total_adviews + position.adviews;
                this.total_pageviews  = this.total_pageviews + position.pageviews;
            });

           this.hideOverview = $transition$.params().hideOverview;

            this.funnelData =  [
                ['Ad Impressions',this.total_adviews,'#6FCEE0'],
                [this.$translate.instant("campaign_pageimpressions"),this.total_pageviews,'#6FCEE0'],
                [this.$translate.instant("campaign_readers"),this.leadstats.total,'#6FCEE0'],
                ['Leads',this.leadstats.qualified,'#6FCEE0']
            ];

            angular.forEach(this.campaign.positions,(position)=>{
                  (<IPositionSelectable>position).selected = true;
                  (<IPositionSelectable>position).selectedTraffic = false;
            });
            let replacetitle = this.campaign.title;
            replacetitle
                .replace('ä', 'ae')
                .replace('Ä', 'Ae')
                .replace('ö', 'oe')
                .replace('Ö', 'Oe')
                .replace('ü', 'ue')
                .replace(/\s*\W/g,'-');
            this.dtOptions =  this.DTOptionsBuilder.newOptions()
            .withDOM('<"html5buttons"B>lTfgitp')

            .withButtons([
                {extend: 'csv'},
                {extend: 'excel'},
                {extend: 'pdf', title: 'Positionen ' +this.campaign.title},

                {extend: 'print',
                    customize:  (win) => {
                        $(win.document.body).addClass('white-bg');
                        $(win.document.body).css('font-size', '10px');

                        $(win.document.body).find('table')
                            .addClass('compact')
                            .css('font-size', 'inherit');
                    }
                }
            ])
            .withOption('order', [1, 'asc']).withOption('lengthMenu', [25, 50, 100])
            .withLanguageSource('assets/localization/dt-'+this.$translate.use()+".json");

            this.filterLeads();

        }

        updateData(){
            this.loading = true;
            let arr_selected = [];
            this.total_adviews = 0;
            this.total_pageviews  = 0;
            angular.forEach(this.campaign.positions,(position)=>{
                  if((<IPositionSelectable>position).selected){
                      arr_selected.push(position._id);

                      this.total_adviews = this.total_adviews + position.adviews;
                      this.total_pageviews  = this.total_pageviews + position.pageviews;
                  }
            });

            if(arr_selected.length == 0){
                arr_selected.push("no_selection");
            }


            this.selectedPositionsLeads  = arr_selected;
            this.LeadsService.getLeadsByCampaign(this.campaign.id,arr_selected.join(),this.$translate.use()).then((result)=>{

                    this.leads = result.data.data;
                    this.leadstats = result.data.metadata;
                    this.headers = result.data.headers;

                    this.funnelData =  [
                        ['Ad Impressions', this.total_adviews,'#6FCEE0'],
                        [this.$translate.instant("campaign_pageimpressions"),this.total_pageviews,'#6FCEE0'],
                        [this.$translate.instant("campaign_readers"),this.leadstats.total,'#6FCEE0'],
                        ['Leads',this.leadstats.qualified,'#6FCEE0']
                    ];
            }).finally(()=>{
                this.filterLeads();
                this.loading = false;
            });



        }

        toggleSelection(id){

            let index = this.selectedPositions.indexOf(id);
            if(index > -1){
                this.selectedPositions.splice(index,1);
            }
            else{
                this.selectedPositions.push(id);
            }

            if(this.selectedPositions.length == 1){
                angular.forEach(this.campaign.positions,(position)=>{
                    if(position._id == this.selectedPositions[0]){
                        this.currentPosition =position;
                    }
                });
            }
            this.selectedPositionsList = this.selectedPositions.join(",");
        }

        filterLeads()
        {
            if(this.leadselect == "all")
            {
                this.leadsFiltered = this.leads;
            }
            else if(this.leadselect == "archive")
            {
                this.leadsFiltered = [];

                this.leads.forEach(lead => {

                    if(lead.exported_leads_date !== null)
                    {
                        this.leadsFiltered.push(lead);
                    }
                });

            }
            else if(this.leadselect == "new")
            {
                this.leadsFiltered = [];
                this.leads.forEach(lead =>
                {
                    if(lead.exported_leads_date === null)
                    {
                        this.leadsFiltered.push(lead);

                    }



                });
            }


        }

    }



